<template>
    <div
        class="preview-container"
        @mouseover="showPlayButtons"
        @mouseleave="hidePlayButtons">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <icon
                        size="20"
                        color="white"
                        name="ig_reels_arrow_back_icon" />
                </div>
            </div>
            <div class="image-holder">
                <div v-if="assetType === 'IMAGE'">
                    <img
                        :key="firstAssetUrl"
                        class=""
                        :src="firstAssetUrl">
                </div>
                <div
                    v-if="controlsActive && assetType === 'VIDEO'"
                    class="play-wrapper"
                    @click="playVideo">
                    <div class="play">
                        <icon
                            v-if="!hidePlayIcon"
                            size="40"
                            color="white"
                            name="audio-play" />
                        <div v-else>
                            <div class="previews-pause-bar" />
                            <div class="previews-pause-bar" />
                        </div>
                    </div>
                </div>
                <div
                    v-if="assetType === 'VIDEO'"
                    class="video-holder">
                    <video
                        :key="firstAssetUrl"
                        ref="video"
                        class="preview-video"
                        muted
                        loop>
                        <source
                            :src="firstAssetUrl"
                            type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="right-elements">
                <div class="low-right-elements">
                    <icon
                        class="my-2"
                        size="50"
                        color="white"
                        name="fb_reels_thumbsup_icon" />
                    <icon
                        class="my-2"
                        size="50"
                        color="white"
                        name="fb_reels_comment_icon" />
                    <icon
                        class="my-2"
                        size="50"
                        color="white"
                        name="fb_reels_share_icon" />
                    <icon
                        class="my-2"
                        size="50"
                        color="white"
                        name="fb_reels_menu_icon" />
                </div>
            </div>
            <div class="bottom-elements">
                <div class="bottom-logo">
                    <img
                        v-if="pageLogo"
                        class="logo"
                        :src="pageLogo">
                    <div
                        v-else
                        class="placeholder-logo">
                        YOUR
                        LOGO
                    </div>
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="primary-text">
                    <div
                        ref="primary"
                        style="line-height:21px">
                        <span>{{ primary }}</span>
                        <span
                            v-if="seeMore"
                            class="seemore"
                            @click="openPrimary"> ...more</span>
                    </div>
                </div>
                <!-- todo fix link css -->
                <a
                    v-if="ctaText"
                    :href="ctaLink"
                    target="_blank"
                    class="cta-button">
                    {{ ctaText }}
                </a>
                <!-- <div class="bottom-comment">
                    Add comment
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        Icon
    },
    props: {
        adData:{
            type: [Object, Array],
            required: true
        }
    },
    data() {
        return {
            hidePlayIcon: false,
            controlsActive: true,
            defaultLogoUrl: 'https://www.facebook.com/images/fb_icon_325x325.png',
            seeMore: false,
            primary: '',
            fullPrimary: ''
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        firstAsset() {
            return this.creatives[0]?.assets.find(asset => asset.aspect_ratio === '9:16') ?? this.creatives[0]?.assets?.[0] ?? {};
        },
        firstAssetUrl() {
            return this.firstAsset?.url;
        },
        assetType() {
            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives?.cta_text;
        },
    },
    watch:{
        adData:{
            handler() {
                this.formatPrimaryText();
            },
            deep: true
        }
    },
    mounted() {
        this.formatPrimaryText();
    },
    methods: {
        formatPrimaryText() {
            // detailed formatting of primary text like we have it in FB business manager
            this.seeMore = false;
            let rawPrimaryText = this.adData.primary_text;
            if (rawPrimaryText) {
                rawPrimaryText = rawPrimaryText.trim();
                this.primary = rawPrimaryText;
                this.fullPrimary = rawPrimaryText;
                const lines = rawPrimaryText.split(/\r\n|\r|\n/);
                this.checkLines(lines);
            }
        },
        openPrimary() {
            this.seeMore = false;
            this.primary = this.fullPrimary;
        },
        checkLines(lines) {
            this.$nextTick(function() {
                const primary = this.$refs.primary;
                const textHeight = primary.offsetHeight;
                const lineHeight = parseInt(primary.style.lineHeight);
                const textLines = textHeight / lineHeight;
                if (lines.length > 2) {
                    this.seeMore = true;
                    const shortenedText = [];
                    lines.forEach((line, index) => {
                        if (index < 2) {
                            shortenedText.push(line);
                        }
                    });
                    this.primary = shortenedText.join('\n');
                    this.primary = this.primary.trim();
                }
                if (lines[lines.length - 1] == '') {
                    lines.splice(lines.length - 1, 1);
                    this.checkLines(lines);
                    return;
                }
                if (textLines > 2) {
                    this.seeMore = true;
                    let lastLine = lines[lines.length - 1];
                    if (lastLine) {
                        const lineWords = lastLine.split(' ');
                        // eslint-disable-next-line no-unused-vars
                        const lastWord = lineWords.pop();
                        lines[lines.length - 1] = lineWords.join(' ');
                        const shortenedText = [];
                        lines.forEach(line => {
                            shortenedText.push(line);
                        });
                        this.primary = shortenedText.join('\n');
                        this.primary = this.primary.trim();
                        // must be recursive until we get desired length
                        setTimeout(() => {
                            this.checkLines(lines);
                        }, 0);
                    }
                }
            });
        },
        playVideo() {
            if (this.hidePlayIcon) {
                this.$refs.video.pause();
                this.hidePlayIcon = false;
            } else {
                this.$refs.video.play();
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container{
    position: relative;
    width: 375px;
    height: 667px;
    display: flex;
    flex-direction: column;
    background-color:$white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 599px) {
        width: 375px;
    }
    @media only screen and (max-width: 400px) {
        width: 320px;
    }
}
.preview-ui{
    position: relative;
    display: flex;
    height:100%;
    flex-direction: column;
}
.top-elements{
    z-index: 5;
    position: absolute;
}
.bottom-elements{
    z-index: 3;
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.bottom-logo{
    z-index: 5;
    display: flex;
}
.bottom-comment{
    z-index: 5;
    // padding-top: 90px;
    padding-bottom: 30px;
    color: #E5E6EB
}
.bottom-description{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 30px;
    color: white;
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.placeholder-logo{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:1px solid white;
    color:white;
    background:black;
    text-align: center;
    font-weight:900;
    font-size: 9px;
    padding-top:8px;
    line-height: 11px;
}
.top-info{
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top:2px;
    color: white;
}
.top-sponsored{
    font-size: 13px;
    text-shadow: 2px 2px 11px #000000;
    color: white;
}
.primary-text{
    font-size: 14px;
    padding: 15px 0;
    padding-right: 45px;
    color: white;
    overflow: hidden;
    position: relative;
    white-space: pre-wrap;
}
.seemore{
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    color: $gray;
    &:hover{
        text-decoration: underline;
    }
}
.image-holder{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    background: black;
    img{
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        vertical-align: top;
    }
    video{
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        vertical-align: top;
    }
    @media only screen and (max-width: 599px) {
        height:375px;
    }
    @media only screen and (max-width: 400px) {
        height: 320px;
    }
}
.video-holder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-elements{
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    bottom: 0px;
    right: 0px;
}
.bottom-site{
    font-size: 13px;
    color: #6C6D71;
}
.bottom-title{
    font-size: 15px;
    color:black;
    font-weight: 700;
}
.bottom-desc{
    font-size: 13px;
    color: #6C6D71;
}
.bottom-link a{
    width: 121px;
    height: 36px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #E5E6EB;
    border-radius: 5px;
    color:black;
    text-decoration: none;
    font-weight: 700;
    margin-top:15px;
    &:hover{
        background: $gray-light;
    }
}
.low-elements{
    padding: 15px;
    display: flex;
    justify-content: space-around;
}
.low-right-elements {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
.cta-button{
    display: inline-block;
    width: 75%;
    height: 40px;
    background: #E5E6EB;
    border-radius: 5px;
    color: black;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    line-height: 40px;
    cursor: pointer;
    &:hover{
        background: #c4c5cc;
    }
}
</style>
